<template>
  <div :class="defaultClasses">
    <BasicLayoutBanner v-show="isShowBanner" />
    <BasicLayoutHeader />

    <main>
      <slot></slot>
      <WidgetSectionSeoContent />
    </main>

    <BasicLayoutFooter v-if="!(isTradeInPage && xlAndLarger)" />
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted } from 'vue'
import type { MetaObject } from 'nuxt/schema'
import { cx } from 'cva'
import { ROUTE_NAMES } from '@/constants'

const defaultClasses = cx([
  'text-secondary',
  'flex',
  'flex-col',
  'justify-between',
  'h-screen',
])

const viewport = useViewport()
const xlAndLarger = computed(() => viewport.isGreaterOrEquals('xl'))

const route = useRoute()
const seoContentStore = useSeoContentStore()
const catalogDeviceStore = useCatalogDeviceStore()
const abStore = useAbStore()

const isCatalogDevicePage = computed(() => route.name === ROUTE_NAMES.CATALOG_DEVICE)
const isTradeInPage = computed(() => route.name === ROUTE_NAMES.TRADE_IN)
const isOneOfCartPage = computed(
  () => route.name === ROUTE_NAMES.CART || route.name === ROUTE_NAMES.THANKS,
)
const isOneOfSubscriptionPage = computed(
  () =>
    route.name === ROUTE_NAMES.SUBSCRIPTION
    || route.name === ROUTE_NAMES.SUBSCRIPTION_FORM
    || route.name === ROUTE_NAMES.SUBSCRIPTION_FORM_FILLED
    || route.name === ROUTE_NAMES.SUBSCRIPTION_OUT_OF_STOCK
    || route.name === ROUTE_NAMES.SUBSCRIPTION_THANKS
    || route.name === ROUTE_NAMES.SUBSCRIPTION_DESCRIPTION,
)

const isShowBanner = computed(
  () =>
    !(
      isTradeInPage.value
      || isOneOfCartPage.value
      || isOneOfSubscriptionPage.value
    ),
)

const meta = computed(() => {
  const result: {
    title?: string
    meta?: { name: string, content: string }
  } = {}

  if (Array.isArray(seoContentStore.list) && seoContentStore.list.length) {
    if (seoContentStore.list[0].Title) {
      result.title = seoContentStore.list[0].Title
    }

    if (seoContentStore.list[0].MetaDescription) {
      result.meta = {
        name: 'description',
        content: seoContentStore.list[0].MetaDescription,
      }
    }
  }

  if (isCatalogDevicePage.value && catalogDeviceStore.item) {
    result.title = `${catalogDeviceStore.item.FullName} | Выгодные цены в маркетплейсе Pedant.Market`
  }

  return result
})

watch(
  () => seoContentStore.list,
  () => useHead(meta.value as MetaObject),
  { deep: true },
)

onMounted(() => {
  abStore.isLoading = true
  abStore.initAllExperiments()
  abStore.isLoading = false
})
</script>